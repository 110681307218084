/* List of projects using the same origin App */

/*
import weihag from './weihag-edit-settings.config';
import ante from './ante-edit-settings.config';
import jv20 from './jv20-edit-settings.config';
import arolla from './arolla-edit-settings.config';
import smartlam from './smartlam-edit-settings.config';
import sodra from './sodra-edit-settings.config';
import peetri from './peetri-edit-settings.config';
import schilliger from './schilliger-edit-settings.config';
import bruno from './bruno-edit-settings.config';
import arboreal from './arboreal-edit-settings.config';
import mm2 from './mm2-edit-settings.config';
import kafe from './kafe-edit-settings.config';
import mosser from './mosser-edit-settings.config';
import eztiles from './eztiles-edit-settings.config';
import schneider from './schneider-edit-settings.config';
import moelven from './moelven-edit-settings.config';
import solis from './solis-edit-settings.config';
import baseProject from './baseProject-edit-settings.config';
import gaishorn2 from './gaishorn2-edit-settings.config';
import sepa from './sepa-edit-settings.config';

const editSettingsList = {
  weihag,
  ante,
  jv20,
  arolla,
  smartlam,
  sodra,
  peetri,
  schilliger,
  bruno,
  arboreal,
  mm2,
  kafe,
  mosser,
  eztiles,
  schneider,
  moelven,
  solis,
  sepa,
  baseProject,
  gaishorn2
};

export default editSettingsList;
*/
const config = require('./' + process.env.REACT_APP_PROJECT_NAME + '-edit-settings.config');
export default config;
