/*
 *
 * Sidebar reducer
 *
 */
import produce from 'immer';
import { DEFAULT_ACTION, SIDEBAR_SHOW, INDICATOR_CHANGE } from './constants';
import { SCANNER_STATUS_RECEIVED, SCANNER_STATUS_CHANGED, SIDEBAR_LIVEINFO } from '../Websocket/constants';
import sidebarList from './config/sidebarList';

const statusItems = sidebarList?.default?.statusItem;
const indicatorItems = sidebarList?.default?.indicatorItems;
const archiveIntervalStep = sidebarList?.default?.archiveIntervalStep;

export const initialState = {
  statusItems,
  lastScannerStatus: new Date('1994-02-02'),
  sidebarShow: true,
  indicatorItems,
  archiveIntervalStep,
  liveData: {}
};

/* eslint-disable default-case, no-param-reassign */
const sidebarReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIDEBAR_SHOW:
        draft.sidebarShow = action.val;
        break;
      case INDICATOR_CHANGE:
        if (state?.indicatorItems) {
          return Object.assign({}, state, {
            indicatorItems: state.indicatorItems.map((indicatorItem) => {
              let ret = Object.assign({}, indicatorItem);

              if (indicatorItem.ident === action.ident) {
                ret = Object.assign({}, indicatorItem, {
                  active: action.active
                });
              }
              return ret;
            })
          });
        } else {
          return null;
        }

      case SCANNER_STATUS_RECEIVED:
        if (draft?.lastScannerStatus) {
          draft.lastScannerStatus = new Date();
        }
        break;
      case SCANNER_STATUS_CHANGED:
        if (state?.indicatorItems && draft.indicatorItems.findIndex((i) => i.ident === 'scanner') != -1) {
          draft.indicatorItems[draft.indicatorItems.findIndex((i) => i.ident === 'scanner')].active = !(draft.lastScannerStatus == null || Math.abs(new Date() - draft.lastScannerStatus) > 5000);
        }
        break;
      case SIDEBAR_LIVEINFO:
        draft.liveData = action.data;
        break;
      case DEFAULT_ACTION:
        break;
    }
  });

export default sidebarReducer;
