/*
 *
 * Websocket actions
 *
 */

import {
  DEFAULT_ACTION,
  CLT_STATUS_RECEIVED,
  CONTROL_STATUS_CHANGED,
  SCANNER_STATUS_RECEIVED,
  SCANNER_STATUS_CHANGED,
  SEND_TO_WS,
  SEND_TO_BACKEND,
  NEW_CLT_STATUS,
  UPDATE_COMPONENT,
  FETCH_ARCHIVE_CLT_STATUS,
  VNC_CLIENT_LIST,
  SIDEBAR_LIVEINFO
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function cltStatusReceived(cltstatus) {
  return {
    type: CLT_STATUS_RECEIVED,
    cltstatus
  };
}

export function vncClientListReceived(list) {
  return {
    type: VNC_CLIENT_LIST,
    list
  };
}

export function scannerStatusReceived() {
  return {
    type: SCANNER_STATUS_RECEIVED
  };
}

export function controlStatusChanged(online) {
  // console.log('controlStatusChanged');
  return {
    type: CONTROL_STATUS_CHANGED,
    online
  };
}

export function scannerStatusChanged() {
  return {
    type: SCANNER_STATUS_CHANGED
  };
}

export function sendToWs(data, wsType) {
  return {
    type: SEND_TO_WS,
    data,
    wsType
  };
}

export function sendToBackend(data, wsType) {
  return {
    type: SEND_TO_BACKEND,
    data,
    wsType
  };
}

export function sendMessage(wsType, data) {
  return {
    type: SEND_TO_WS,
    data,
    wsType
  };
}

export function newCltStatus(status) {
  return {
    type: NEW_CLT_STATUS,
    status
  };
}

export function updateComponent(objectName, data) {
  return {
    type: UPDATE_COMPONENT,
    objectName,
    data
  };
}

export function sidebarLiveInfo(data) {
  return {
    type: SIDEBAR_LIVEINFO,
    data
  };
}

sidebarLiveInfo;

export function fetchArchiveStatus(statusTime) {
  return {
    type: FETCH_ARCHIVE_CLT_STATUS,
    statusTime
  };
}
