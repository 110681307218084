import FormattedMessage from '../../components/TranslationWrapper';

import globalMessages from '../../globalMessages';
import { roundToNdecimals } from '../helpers';
function isFloat(n) {
  n = parseFloat(n);
  return Number(n) === n;
}

/* Check data of layers */
export function orderValidateChildData(layer, index, toServer, childFields) {
  const keys = [];
  let ret = false;
  const errors = { layers: [] };
  errors.layers[index] = {};
  // create errors to automatically handle blank fields via props in reducer
  childFields.forEach((item) => {
    item.forEach((item1) => {
      if (item1.blank === false) {
        if (layer[item1.key] === '' || isNaN(layer[item1.key])) {
          console.log('NaN');
          errors.layers[index][item1.key] = <FormattedMessage {...globalMessages.notBlank} identifier={'notBlank'} />;
          ret = true;
        }
      }
      if (typeof item1.validation !== 'undefined' && item1.validation.indexOf('float') > -1 && layer[item1.key] !== undefined) {
        if (!isFloat(layer[item1.key])) {
          keys.push(item1.key);
          errors.layers[index][item1.key] = <FormattedMessage {...globalMessages.mustNumber} identifier={'mustNumber'} />;
          ret = true;
        }
      }
      if (typeof item1.conditionals !== 'undefined') {
        item1.conditionals.forEach((conditional) => {
          if (typeof conditional.conditional !== 'undefined' && typeof conditional.conditionalValue !== 'undefined' && typeof conditional.conditionalResult !== 'undefined') {
            const fieldName = conditional.conditional;
            const value = conditional.conditionalValue;
            const parentFieldName = conditional.conditionalResult;
            const calcValue = layer[item1.key];

            if (layer[fieldName] === value) {
              let oversize = 0;
              let multiplier = 0;
              if (typeof toServer[conditional.oversizeFieldName] !== 'undefined') {
                oversize = -parseFloat(toServer[conditional.oversizeFieldName]);
              }
              if (typeof conditional.conditionalMultiplier !== 'undefined') {
                multiplier = layer[conditional.conditionalMultiplier];
              }
              if (typeof toServer[parentFieldName] !== 'undefined' && toServer[parentFieldName] !== '') {
                const parentValue = toServer[parentFieldName];
                let res;
                if (multiplier !== 0) {
                  res = parseFloat(calcValue) * parseFloat(multiplier) + parseFloat(oversize);
                } else {
                  res = parseFloat(calcValue) + parseFloat(oversize);
                }

                if (Math.ceil(parentValue) !== Math.ceil(res)) {
                  keys.push(item1.key);
                  if (multiplier !== 0 && oversize !== 0) {
                    if (oversize < 0) {
                      errors.layers[index][item1.key] = `${calcValue}mm * ${multiplier} ${oversize}mm != ${parentValue} mm, is ${Math.ceil(res)}mm`;
                    } else {
                      errors.layers[index][item1.key] = `${calcValue}mm * ${multiplier} +${oversize}mm != ${parentValue} mm, is ${Math.ceil(res)}mm`;
                    }
                  } else if (multiplier === 0 && oversize !== 0) {
                    errors.layers[index][item1.key] = `${calcValue}mm ${oversize}mm != ${parentValue} mm`;
                  } else if (multiplier === 0 && oversize === 0) {
                    errors.layers[index][item1.key] = `${calcValue}mm != ${parentValue} mm`;
                  }
                  ret = true;
                }
              }
            }
          }
        });
      }
    });
  });

  return { ret, errors };
}

export function orderValidateModelData(toServer, fields, childFields) {
  const keys = [];
  let ret = false;
  const errors = { layers: [] };
  // create errors to automatically handle blank fields via props in reducer
  console.log('fields', fields);
  fields.forEach((item) => {
    item.forEach((item1) => {
      if (item1.blank === false) {
        if (toServer[item1.key] === '' || toServer[item1.key] === undefined) {
          errors[item1.key] = <FormattedMessage {...globalMessages.notBlank} identifier={'notBlank'} />;
          ret = true;
        }
      }
      if (typeof item1.validation !== 'undefined' && item1.validation.indexOf('float') > -1) {
        if (!isFloat(toServer[item1.key])) {
          keys.push(item1.key);
          errors[item1.key] = <FormattedMessage {...globalMessages.mustNumber} identifier={'mustNumber'} />;
          ret = true;
        }
      }

      if (typeof item1.validation !== 'undefined' && item1.validation.indexOf('aboveZero') > -1) {
        if (typeof errors[item1.key] === 'undefined' && toServer[item1.key] <= 0) {
          keys.push(item1.key);
          errors[item1.key] = <FormattedMessage {...globalMessages.aboveZero} identifier={'aboveZero'} />;
          ret = true;
        }
      }

      if (typeof item1.validation !== 'undefined') {
        item1.validation.map((item) => {
          if (typeof item === 'object') {
            if (item.type === 'sum') {
              let sum = 0;
              if (toServer.layers.length > 0) {
                toServer.layers.map((layer) => {
                  sum += parseFloat(layer[item.childField]);
                  sum = roundToNdecimals(sum, 2);
                  return 0;
                });

                // sum = Math.round(sum);
                if (sum !== parseFloat(toServer[item1.key])) {
                  keys.push(item1.key);
                  errors[item1.key] = `sum of ${item.desc} != ${toServer[item1.key]}, is ${sum}`;
                  ret = true;
                }
              }
            } else if (item.type === 'between') {
              let betweenError = false;
              if (item.min !== undefined && parseFloat(toServer[item1.key]) < item.min) betweenError = true;
              if (item.max !== undefined && parseFloat(toServer[item1.key]) > item.max) betweenError = true;
              if (betweenError) {
                keys.push(item1.key);
                errors[item1.key] = `value must be between ${item.min} and ${item.max}`;
              }
            }
          }
          return 0;
        });
      }
    });
  });

  let ret1 = null;
  toServer.layers.map((item, index) => {
    ret1 = orderValidateChildData(item, index, toServer, childFields);
    if (ret1.ret) {
      ret = true;
    }
    errors.layers[index] = ret1.errors.layers[index];
    return 0;
  });
  return { ret, errors };
}

export function generalValidator(formState, form, globalSettings) {
  let ret = false;
  const errors = { item: {}, newChild: {} };
  //console.log('formState', formState, 'form', form, 'global', globalSettings);
  // create errors to automatically handle blank fields via props in reducer
  // var errors = props.form.modelValidator(props.formState, props.form, props.settings);
  form.fields.forEach((row) => {
    row.forEach((column) => {
      column.forEach((formItem) => {
        try {
          const data = formState.item[formItem.key];
          const fieldError = fieldValidator(data, formItem, globalSettings);
          if (fieldError.ret) {
            errors.item[formItem.key] = fieldError.error;
            ret = true;
          }
        } catch (e) {
          console.log('fieldValidator exception', e);
        }
      });
    });
  });

  if (form?.childFieldName && formState?.item && Array.isArray(formState.item?.[form.childFieldName])) {
    errors[form.childFieldName] = {};

    for (let i = 0; i < formState.item[form.childFieldName].length; i++) {
      errors[form.childFieldName][i] = {};
      form.childFields.forEach((row) => {
        row.forEach((column) => {
          column.forEach((formItem) => {
            try {
              const data = formState.item[form.childFieldName][i][formItem.key];
              const fieldError = fieldValidator(data, formItem, globalSettings);
              if (fieldError.ret) {
                errors[form.childFieldName][i][formItem.key] = fieldError.error;
                ret = true;
              }
            } catch (e) {
              console.log('fieldValidator exception', e);
            }
          });
        });
      });
    }
  }

  //console.log('fieldValidator ret', ret, ' errors: ', errors);

  return { ret, errors };
}

// export function generalChildValidator(data, item, globalSettings) {}

export function fieldValidator(value, formItem, globalSettings) {
  //console.log('fieldValidator formItem: ', formItem, ' value: ', value);

  let ret = false;
  let error = '';

  /* Validating validationFunction from models*/
  /* Moved to ListViewAdd 
  if (typeof formItem?.additional_data?.validationFunction !== 'undefined') {
    error = formItem?.additional_data?.validationFunction(value);
    console.log('error: ', error);
    if (error.length > 0) ret = true;
  }
*/
  /* */

  if (formItem.blank === false) {
    if (value === '' || value === undefined) {
      error = <FormattedMessage {...globalMessages.notBlank} identifier={'notBlank'} />;
      ret = true;
    }

    if (formItem?.validation !== undefined && formItem.validation[0] === 'select') {
      if (value < 0) {
        error = 'select option';
        ret = true;
      }
    }
  }

  if (typeof formItem.validation !== 'undefined' && formItem.validation.indexOf('float') > -1) {
    if (isNaN(value)) {
      error = <FormattedMessage {...globalMessages.mustNumber} identifier={'mustNumber'} />;
      ret = true;
    }
  }

  if (typeof formItem.validation !== 'undefined' && formItem.validation.indexOf('aboveZero') > -1) {
    if (typeof error === 'undefined' && value <= 0) {
      error = <FormattedMessage {...globalMessages.aboveZero} identifier={'aboveZero'} />;
      ret = true;
    }
  }

  if (typeof formItem.modelValidation !== 'undefined') {
    formItem.modelValidation.forEach(function (validationRule) {
      let itemValue = 0;
      if (!isNaN(value)) itemValue = parseFloat(value);
      const fromGlobalSettings = validationRule.fromGlobal;
      let compareValue = 0;
      if (fromGlobalSettings) compareValue = parseFloat(globalSettings[validationRule.globalKey]);
      switch (validationRule.valueLimit) {
        case 'above':
          if (itemValue > compareValue) {
            const translated = [];
            translated.push(<FormattedMessage {...globalMessages[validationRule.errorMessage]} />);
            translated.push(' > ' + compareValue);
            ret = true;
            if (validationRule.errorMessage !== undefined) error = translated;
            else error = 'above error, no message';
          }
          break;
        case 'below':
          if (itemValue < compareValue) {
            ret = true;
            const translated = [];
            translated.push(<FormattedMessage {...globalMessages[validationRule.errorMessage]} />);
            translated.push(' < ' + compareValue);
            if (validationRule.errorMessage !== undefined) error = translated;
            else error = 'bellow error, no message';
          }
          break;
        default:
          break;
      }
    });
  }

  return { ret, error };
}

export function planerSetupValidator(formState, form, globalSettings) {
  let ret = false;
  const errors = { item: {}, newChild: {} };

  if (Array.isArray(form.fields)) {
    form.fields.forEach((row) => {
      if (Array.isArray(row)) {
        row.forEach((column) => {
          if (Array.isArray(column)) {
            column.forEach((formItem) => {
              try {
                if (formItem && formItem.key) {
                  const data = formState.item ? formState.item[formItem.key] : undefined;
                  const fieldError = planerSetupValidationFunction(data, formItem, formState);
                  if (fieldError.ret) {
                    errors.item[formItem.key] = fieldError.error;
                    ret = true;
                  }
                }
              } catch (e) {
                console.log('fieldValidator exception', e);
              }
            });
          }
        });
      }
    });
  } else {
    console.warn('form.fields is not an array or is undefined');
  }

  return { ret, errors };
}

export function planerSetupValidationFunction(value, formItem, formState) {
  let ret = false;
  let error = '';

  // Custom validation logic
  if (formItem.key === 'turningBeforePress') {
    const turningEverySecond = formState.item.turningEverySecond;
    const turningEveryFirst = formState.item.turningEveryFirst;

    /*     if (value) {
      // If turningBeforePress is checked, one (but not both) of the others must be checked
      if ((turningEverySecond && turningEveryFirst) || (!turningEverySecond && !turningEveryFirst)) {
        ret = true;
        error = <FormattedMessage {...globalMessages.turningBeforePressError1} identifier={'turningBeforePressError1'} />;
      }
    } else { */
    // If turningBeforePress is not checked, neither of the others should be checked
    if ((turningEverySecond || turningEveryFirst) && !value) {
      ret = true;
      error = <FormattedMessage {...globalMessages.turningBeforePressError2} identifier={'turningBeforePressError2'} />;
    }
    /*     } */
  }

  return { ret, error };
}
