import history from './history.js';
import jwtDecode from 'jwt-decode';
import mainConfig from '../containers/Productionline/productionConfig';

const localStorage = global.window.localStorage;
const configuration = mainConfig.components;
const auth = {
  getToken() {
    return localStorage.getItem('token');
  },

  getRefreshToken() {
    return localStorage.getItem('refresh');
  },

  async login(username, password) {
    // const token = auth.getToken();
    console.log('## auth/login API token: ', username, ' / ', password);
    try {
      const response = await fetch('/api/v1/auth/login/', {
        method: 'POST',
        body: JSON.stringify({
          username,
          password
        }),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      });
      let promise;
      console.log('1', response);
      if (response.status == 200) {
        console.log('SUCCESS login');
        promise = new Promise((resolve) => {
          response.json().then((json) => {
            localStorage.setItem('token', json.access);
            localStorage.setItem('refresh', json.refresh);
            const decoded_token = jwtDecode(json.access);
            resolve({ response, token: decoded_token });
          });
        });
      } else {
        console.log('ERROR login: ', response.status);
        promise = Promise.resolve({ response, token: null });
      }
      return await promise;
    } catch (error) {
      console.log(error);
      return await Promise.resolve({ response: response || { status: 600 }, token: null });
    }
  },

  async reload(storageToken, setLoginData, initiateLogin, setAuthState, setCurrentUser, currentUser) {
    // console.log('## reload API token: ', storageToken);
    try {
      const response = await fetch('/api/v1/auth/all/get_user/', {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storageToken}`
        })
      });
      let promise;
      if (response.status == 200) {
        promise = new Promise((resolve) => {
          response.json().then(async (json) => {
            try {
            } catch (e) {
              console.log('exception', e);
            }
            setCurrentUser({ ...json });
            setLoginData(json);
            const prodLineSize = configuration.reduce((count, item) => count + item.length, 0);
            if (!localStorage.getItem('settings')) {
              const settingsData = Array.from({ length: prodLineSize }, () => true);
              localStorage.setItem('settings', JSON.stringify(settingsData));
            } else {
              if (prodLineSize !== JSON.parse(localStorage.getItem('settings')).length) {
                const settingsData = Array.from({ length: prodLineSize }, () => true);
                localStorage.setItem('settings', JSON.stringify(settingsData));
              }
            }
          });
        });
      } else {
        auth.logout();
        promise = Promise.resolve(false);
      }
      return await promise;
    } catch (error) {
      console.log(error);
      return await Promise.resolve(false);
    }
  },

  async refreshToken() {
    try {
      const response = await fetch('/api/v1/auth/token_refresh/', {
        method: 'POST',
        body: JSON.stringify({
          refresh: localStorage.getItem('refresh')
        }),
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.getToken()}`
        })
      });
      let promise;
      if (response.status == 200) {
        promise = new Promise((resolve) => {
          response.json().then((json) => {
            localStorage.setItem('token', json.access);
            localStorage.setItem('refresh', json.refresh);
            resolve(true);
          });
        });
      } else if (response.status == 400) {
        auth.logout();
        promise = Promise.resolve({
          success: false,
          type: 'logout'
        });
      } else {
        auth.logout();
        promise = Promise.resolve({
          success: false,
          type: 'server-down'
        });
      }
      return await promise;
    } catch (error) {
      console.log(error);
      const promise_1 = Promise.resolve({
        success: false,
        type: 'server-down'
      });
      return await promise_1;
    }
  },

  async verifyToken() {
    console.log('## verifyToken ##');
    try {
      const response = await fetch('/api/v1/auth/login-token-verify/', {
        method: 'POST',
        body: JSON.stringify({
          token: `${auth.getToken()}`
        }),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      });
      let promise;
      if (response.status == 200) {
        promise = new Promise((resolve) => {
          response.json().then((json) => {
            resolve(json);
          });
        });
      } else if (response.status == 400) {
        auth.logout();
        promise = Promise.resolve({
          success: false,
          type: 'logout'
        });
      } else {
        auth.logout();
        promise = Promise.resolve({
          success: false,
          type: 'server-down'
        });
      }
      return await promise;
    } catch (error) {
      console.log(error);
      return await Promise.resolve({
        success: false,
        type: 'server-down'
      });
    }
  },

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('settings');
    localStorage.removeItem('userView');
    history.push('/login');
    return Promise.resolve(true);
  },

  loggedIn() {
    return true;
    // return !!localStorage.token;
  }
};

export default auth;
