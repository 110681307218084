import { SEND_GLOBAL_MESSAGE, CLEAR_GLOBAL_MESSAGE, SHOW_GLOBAL_MESSAGE } from './constants';
import { uuidv4 } from '../../utils/helpers';

export const sendGlobalMessage = (title, message) => {
  const id = uuidv4();
  return {
    type: SEND_GLOBAL_MESSAGE,
    payload: {
      type: 'global_message',
      data: { title, message },
      uuid: id
    }
  };
};

export const clearGlobalMessage = () => ({
  type: CLEAR_GLOBAL_MESSAGE
});

export const showGlobalMessage = (message) => ({
  type: SHOW_GLOBAL_MESSAGE,
  payload: message
});
