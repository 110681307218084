import { useState, useEffect, useContext } from 'react';
import { UnitSystemContext } from './unitSystemContext';

const unitConversions = {
  length: {
    base: 'meters',
    units: {
      meters: 1,
      feet: 3.28084,
      inches: 39.3701,
      miles: 0.000621371,
      centimeters: 100,
      millimeters: 1000,
      kilometers: 0.001
    }
  },
  weight: {
    base: 'kilograms',
    units: {
      kilograms: 1,
      pounds: 2.20462,
      ounces: 35.274
    }
  },
  volume: {
    base: 'cubic meters',
    units: {
      'cubic meters': 1,
      'cubic centimeters': 1000000,
      'cubic inches': 61023.7441,
      'cubic feet': 35.3147,
      'cubic miles': 2.39913e-10,
      liters: 1000,
      milliliters: 1000000,
      gallons: 264.172,
      quarts: 1056.69,
      pints: 2113.38
    }
  }
};

function parseDecimalString(numberString, decimalPlaces) {
  const decimalIndex = numberString.indexOf('.');

  if (decimalIndex === -1) {
    return numberString;
  }

  const integerPart = numberString.slice(0, decimalIndex);
  const decimalPart = numberString.slice(decimalIndex + 1);

  const truncatedDecimal = decimalPart.slice(0, decimalPlaces);

  return `${integerPart}.${truncatedDecimal}`;
}

const useUnitConverter = () => {
  const { unitSystem, toggleUnitSystem } = useContext(UnitSystemContext);

  const convertUnit = (value, type, fromUnit, targetUnit, decimalPoints = 3) => {
    if (unitSystem === 'metric' || value === '' || value === '.') {
      if (value !== '' && value !== '.') {
        const numericValue = parseFloat(value);
        if (!isNaN(numericValue)) {
          return numericValue.toFixed(decimalPoints);
        }
      }
      return value;
    }

    const { units } = unitConversions[type];
    if (!units[fromUnit] || !units[targetUnit]) {
      throw new Error('Invalid unit provided');
    }

    const valueString = value.toString();
    const endsWithDecimalPoint = valueString.endsWith('.');

    const decimalPartMatch = valueString.match(/\.(\d*)$/);
    const decimalPart = decimalPartMatch ? decimalPartMatch[1] : '';
    const decimalPlaces = decimalPart.length;

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) {
      return value;
    }

    const factorToBase = 1 / units[fromUnit];
    const factorFromBase = units[targetUnit];
    const convertedValue = numericValue * factorToBase * factorFromBase;

    let resultValue = convertedValue.toFixed(decimalPlaces);

    if (endsWithDecimalPoint) {
      resultValue += '.';
    }

    resultValue = parseDecimalString(resultValue, decimalPoints);

    return resultValue;
  };

  const getUnitDisplay = (firstUnit, secondUnit) => {
    return unitSystem === 'metric' ? firstUnit : secondUnit;
  };

  return {
    unitSystem,
    toggleUnitSystem,
    convertUnit,
    getUnitDisplay
  };
};

export default useUnitConverter;
