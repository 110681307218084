/**
 *
 * SwitchBox
 *
 */

import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import styled from 'styled-components';
import './test.css';
import { sendToWs } from '../../../../containers/Websocket/actions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Switch from '@material-ui/core/Switch';

const SubInfo = styled.div`
  font-size: smaller;
  background: #ebedef;
  text-align: center;
`;

const DataInfo = styled.div`
  background: #ffffffd4;
  padding: 10px;
  //box-shadow: 0px 0px 3px 1px #636f8373;
  display: inline-block;
  width: 100%;
`;

export function SwitchBox(props) {
  const [isSwitched1, setIsSwitched1] = useState(props.productionstatus?.['zink1-WSdirection'] != 'WS1'); // Z1-WS
  const [isSwitched2, setIsSwitched2] = useState(props.productionstatus?.['zink2-WSdirection'] != 'WS1'); // Z2-WS2
  const [isSwitchedP1, setIsSwitchedP1] = useState(props.productionstatus?.['zink2-planerDirection'] != 'H1'); // Z1-HOBL
  const [isSwitchedP2, setIsSwitchedP2] = useState(props.productionstatus?.['zink1-planerDirection'] != 'H1'); // Z2-HOBL

  useEffect(() => {
    setIsSwitched1(props.productionstatus?.['zink2-WSdirection'] != 'WS1');
    setIsSwitched2(props.productionstatus?.['zink1-WSdirection'] != 'WS1');
    setIsSwitchedP1(props.productionstatus?.['zink2-planerDirection'] != 'H1');
    setIsSwitchedP2(props.productionstatus?.['zink1-planerDirection'] != 'H1');
  }, [props.productionstatus]);

  const handleSwitchChange1 = (event) => {
    const newSwitchState1 = event.target.checked;
    setIsSwitched1(newSwitchState1);
    const valueToSend1 = newSwitchState1 ? 'WS2' : 'WS1';
    props.sendToWs({ valueToSend1: valueToSend1, zink: 'zink2' }, 'switch-wintersteiger');
  };

  const handleSwitchChange2 = (event) => {
    const newSwitchState2 = event.target.checked;
    setIsSwitched2(newSwitchState2);
    const valueToSend2 = newSwitchState2 ? 'WS2' : 'WS1';
    props.sendToWs({ valueToSend1: valueToSend2, zink: 'zink1' }, 'switch-wintersteiger');
  };

  const handleSwitchChangeP1 = (event) => {
    const newSwitchStateP1 = event.target.checked;
    setIsSwitchedP1(newSwitchStateP1);
    const valueToSend1 = newSwitchStateP1 ? 'H2' : 'H1';
    props.sendToWs({ valueToSend1, zink: 'zink2' }, 'switch-planner');
  };

  const handleSwitchChangeP2 = (event) => {
    const newSwitchStateP2 = event.target.checked;
    setIsSwitchedP2(newSwitchStateP2);
    const valueToSend1 = newSwitchStateP2 ? 'H2' : 'H1';
    props.sendToWs({ valueToSend1, zink: 'zink1' }, 'switch-planner');
  };

  return (
    <Wrapper>
      <br />
      <span>
        <h3>Zinc 1</h3>
        <div>
          WS1
          <Switch checked={isSwitched2} onChange={handleSwitchChange2} color="primary" name="switch-wintersteiger" />
          WS2
        </div>
        <div>
          H1
          <Switch checked={isSwitchedP2} onChange={handleSwitchChangeP2} color="primary" name="switch-planerDirection" />
          H2
        </div>
        <h3>Zinc 2</h3>
        <div>
          WS1
          <Switch checked={isSwitched1} onChange={handleSwitchChange1} color="primary" name="switch-wintersteiger" />
          WS2
        </div>
        <div>
          H1
          <Switch checked={isSwitchedP1} onChange={handleSwitchChangeP1} color="primary" name="switch-planerDirection" />
          H2
        </div>
        <br />
      </span>
    </Wrapper>
  );
}

SwitchBox.propTypes = {
  status: PropTypes.object,
  sendToWs: PropTypes.func
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    sendToWs: (identifier, data) => dispatch(sendToWs(identifier, data)),
    dispatch
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SwitchBox);
