import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { showKeyboard, sendInputKeyboard } from '../../../containers/App/actions';
import { connect } from 'react-redux';
import { makeSelectKeyboardState } from '../../../containers/App/selectors';

class KeyboardedInput extends React.Component {
  static propTypes = {
    name: PropTypes.any,
    className: PropTypes.any,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.any,
    type: PropTypes.any,
    min: PropTypes.any,
    max: PropTypes.any,
    step: PropTypes.any,
    pattern: PropTypes.any,
    readOnly: PropTypes.any,
    enabled: PropTypes.any,
    defaultKeyboard: PropTypes.any,
    secondaryKeyboard: PropTypes.any,
    opacity: PropTypes.any,
    isDraggable: PropTypes.any,
    isFirstLetterUppercase: PropTypes.any,
    dataset: PropTypes.any,
    onChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.object,
    id: PropTypes.string,
    size: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocusLost = this.handleFocusLost.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideKeyboard = this.hideKeyboard.bind(this);

    this.state = {
      showKeyboard: true,
      input: null
    };
  }

  componentDidMount() {
    this.input.addEventListener('input', this.handleChange);
    this.oldValue = this.props.value;
    this.props.sendInputKeyboard(this.input);
  }

  componentWillUnmount() {
    this.input.removeEventListener('input', this.handleChange);
  }

  handleChange(event) {
    this.props.onChange(event.target.value);
    this.props.sendInputKeyboard(this.input);
    event.stopPropagation();
  }

  handleFocus(event) {
    // Prevent blinking of the keyboard if opaque
    setTimeout(() => {
      if (this.input != null) {
        this.input.focus();
        // this.input.select();
        this.props.showKeyboard(true);
        this.props.sendInputKeyboard(this.input);
      }
    }, 0);
  }

  handleFocusLost() {
    const that = this;
    setTimeout(() => {
      if (
        !document.activeElement.classList.contains('react-draggable-transparent-selection') &&
        !document.activeElement.classList.contains('keyboard-button') &&
        !document.activeElement.classList.contains('keyboard') &&
        !document.activeElement.classList.contains('keyboard-row')
      ) {
        // that.setState({ ...that.state, showKeyboard: true });
        this.props.showKeyboard(false);
        // this.props.sendInputKeyboard(null);
      }
    }, 0);
  }

  hideKeyboard() {
    this.setState({ ...this.state, showKeyboard: true });
    // this.props.showKeyboard(false);
  }

  render() {
    // console.log('KeyboarderInput: ', this.input)
    return (
      <div>
        <TextField
          // type={'number'}
          id={this.props.id}
          error={this.props.error}
          helperText={this.props.helperText}
          color="secondary"
          variant={this.props.variant !== undefined ? this.props.variant : 'outlined'}
          disabled={this.props.disabled}
          label={this.props.label}
          size={this.props.size}
          inputProps={{
            step: this.props.step,
            className: this.props.className,
            style: this.props.style,
            value: this.props.value,
            type: this.props.type,
            onFocus: this.handleFocus,
            onBlur: this.handleFocusLost,
            min: this.props.min,
            max: this.props.max,
            step: this.props.step,
            pattern: this.props.pattern,
            onChange: this.handleChange,
            onClick: (e) => {
              e.stopPropagation();
            },
            onKeyDown: (e) => {
              e.stopPropagation();
            },
            shrink: true,
            readOnly: this.props.readOnly === true,
            ref: (e) => (this.input = e)
          }}
          InputLabelProps={{
            shrink: true
          }}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showKeyboard: (open) => dispatch(showKeyboard(open)),
    sendInputKeyboard: (input) => dispatch(sendInputKeyboard(input))
  };
}

const mapStateToProps = makeSelectKeyboardState();

KeyboardedInput.propTypes = {
  showKeyboard: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyboardedInput);
