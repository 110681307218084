import produce from 'immer';
export function orderChildCalculateValues(form, layer, index, force, changeModelState, toServer, settings) {
  console.log('orderChildCalculateValues: ', form, layer, toServer, settings);
  const change = JSON.parse(JSON.stringify(layer));
  const orient = parseInt(layer.orientation);
  const rotation = parseInt(layer.rotation);
  change.layerNo = layer.layerNo;
  change.quality = layer.quality;
  change.lamellaLength = layer.lamellaLength;
  change.lamellaThickness = layer.lamellaThickness;
  change.lamellaWidth = layer.lamellaWidth;
  change.rawThickness = layer.rawThickness;
  change.rawWidth = layer.rawWidth;
  change.widthoffset = layer.widthoffset != undefined && layer.widthoffset !== '' ? layer.widthoffset : [];
  console.log('change1: ', change);
  /* CLT */
  if (!toServer.isKVH) {
    let layerMinTakeaway, /* layerMaxTakeaway, */ layerOversize, layerWidthOversize;
    try {
      if (settings !== undefined) {
        layerMinTakeaway = settings.minTakeaway;
        // layerMaxTakeaway = settings.maxTakeaway;
        layerOversize = settings.lOversize;
        layerWidthOversize = settings.qOversize;
      }
    } catch (e) {
      // parse error
    }

    const minTakeAway = !isNaN(layerMinTakeaway) ? layerMinTakeaway : 5; // mm
    const boardWidth = layer.rawWidth;
    const panelWidth = parseFloat(toServer.panelWidth);
    const panelLength = parseFloat(toServer.panelLength);
    const oversize = !isNaN(layerOversize) && !force ? parseFloat(layerOversize) : parseFloat(toServer.oversize);
    const oversizeWidth = !isNaN(layerWidthOversize) && !force ? parseFloat(layerWidthOversize) : parseFloat(toServer.oversizeWidth);
    let boards = 0;
    let panelDimension = -1;

    // console.log('VALS: ', minTakeAway, boardWidth, panelWidth, panelLength, oversize, oversizeWidth);
    if (orient == 1) {
      // L
      panelDimension = panelWidth + oversize;
    }
    if (orient == 2) {
      // Q
      panelDimension = panelLength + oversizeWidth;
    }

    const boardPlanedMinWidth = boardWidth - minTakeAway;
    boards = Math.ceil(panelDimension / boardPlanedMinWidth);
    const takeaway = boardWidth - panelDimension / boards;
    // console.log('takeaway: ', boardWidth, panelDimension, boards);

    // qtakeaway
    /* for (var i = minTakeAway; i <= maxTakeAway; i++) {
      var iWidth = boardWidth - (i/10);

      var numberOfBoards = panelDimension/iWidth;
      var diff = Math.abs(Math.ceil(numberOfBoards) - numberOfBoards)*i;
      var odiff = Math.abs((Math.ceil(numberOfBoards)*i)/1000);

      if ((diff+odiff) < waste) {
        boards = Math.ceil(numberOfBoards);
        takeaway = (i/10);
        waste = diff;
      }
    } */

    let width = boardWidth - takeaway;
    width = Math.round(width * 100) / 100;
    const number_of_lamellas = boards;

    /* L - LONG */
    if (orient == 1) {
      const lamellaLength = toServer.panelLength;
      if (width > 0) {
        change.lamellaWidth = width;
        change.numOfLamellas = number_of_lamellas;
        change.lamellaLength = lamellaLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 1;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 2) {
      /* Q - CROSS */
      const lamellaLength = toServer.panelWidth;
      if (width > 0) {
        change.lamellaWidth = width;
        change.numOfLamellas = number_of_lamellas;
        change.lamellaLength = lamellaLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 2;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    }

    if (rotation !== undefined) {
      change.rotation = rotation;
    } else {
      change.rotation = layer.rotation;
    }

    // arboreal
    if (isNaN(change.rotation)) {
      change.rotation = 0;
    }

    change.orientation = orient;
    if (index >= 0) {
      changeModelState((prevNewState) =>
        produce(prevNewState, (draft) => {
          draft.item[form.childFieldName][index] = change;
        })
      );
    } else {
      changeModelState((prevNewState) =>
        produce(prevNewState, (draft) => {
          draft.newChild = change;
        })
      );
    }
  } else {
    /* Kvh */
    /* try {
      let lamellas = parseInt(layer.numLamellasPerLayer);
      let layers = parseInt(layer.numKVHLayers);
      let width = parseInt(layer.lamellaWidth);
      let length = parseInt(layer.lamellaLength);

      if (!isNaN(lamellas) && !isNaN(layers)) {
        change.numOfLamellas =  lamellas*layers ;
        if (index >= 0) {
          changeModelState((prevNewState) =>
              produce(prevNewState,  draft  => {draft.toServer.layers[index] = change})
          );
        } else {
          changeModelState((prevNewState) =>
          produce(prevNewState,  draft  => {draft.newChild= change})
      );
        }
      }

      if (!isNaN(lamellas) && !isNaN(width)) {
        changeModelState((prevNewState) =>
              produce(prevNewState,  draft  => {draft.toServer.panelWidth = lamellas*width})
          );
      }

      if (!isNaN(length)) {
        changeModelState((prevNewState) =>
              produce(prevNewState,  draft  => {draft.toServer.length = length})
          );
      }
    } catch (err) {
      // non parseable entry
      console.log(err);
    } */
  }
}

export function arborealMaterialCodeCalculator(form, formState, changeModelState) {
  // console.log('EEE', 'form', form, 'formState', formState, 'changeModelState', changeModelState);
  const change = JSON.parse(JSON.stringify(formState));
  if (change.planningNumber > 4 || change.planningNumber % 2 != 0) {
    // lahko je 0, 2 ali 4, vse ostalo vrže na 0
    change.planningNumber = 0;
  }

  let quality = change.quality;
  if (change.quality.length == 1) {
    // N -> NN
    quality = change.quality + change.quality;
  }

  change.materialCode = 'FIN-' + change.strengthString + '-' + quality + '-C' + change.planningNumber + '-0' + change.rawThickness + 'X' + change.rawWidth + 'X' + change.lamellaLength;

  changeModelState((prevNewState) =>
    produce(prevNewState, (draft) => {
      draft.item = change;
      // console.log('WADAFAK', draft.item);
    })
  );
}

export function schneiderModelCalculator(form, formState, changeModelState) {
  // console.log('EEE', 'form', form, 'formState', formState, 'changeModelState', changeModelState);
  const change = JSON.parse(JSON.stringify(formState));

  const rawThickness = change.rawThickness;
  const thickness = change.lamellaThickness;
  /*
  if (thickness < rawThickness / 2) {
    change.splitWood = true;
  } else {
    change.splitWood = false;
  }
  */
  changeModelState((prevNewState) =>
    produce(prevNewState, (draft) => {
      draft.item = change;
      // console.log('WADAFAK', draft.item);
    })
  );
}

export function schilligerChildCalculateValues(form, layer, index, force, changeModelState, toServer, settings) {
  //console.log('schilligerChildCalculateValues: ', form, layer, toServer, settings);
  //console.log('schilligerChildCalculateValues layer: ', layer);
  //console.log('schilligerChildCalculateValues toServer: ', toServer);

  const change = JSON.parse(JSON.stringify(layer));
  const orient = parseInt(layer.orientation);
  const rotation = parseInt(layer.rotation);

  const LAMELLA_WIDTH = 100;
  const INSULATION_WIDTH = 262.5;

  change.layerNo = layer.layerNo;
  change.quality = layer.quality;
  change.lamellaLength = layer.lamellaLength;
  change.lamellaThickness = layer.lamellaThickness;
  change.lamellaWidth = layer.lamellaWidth;
  change.rawThickness = layer.rawThickness;
  change.rawWidth = layer.rawWidth;
  change.widthoffset = layer.widthoffset != undefined && layer.widthoffset !== '' ? layer.widthoffset : [];

  /* CLT */
  if (!toServer.isKVH) {
    let layerMinTakeaway, /* layerMaxTakeaway, */ layerOversize, layerWidthOversize;
    try {
      if (settings !== undefined) {
        layerMinTakeaway = settings.minTakeaway;
        // layerMaxTakeaway = settings.maxTakeaway;
        layerOversize = settings.lOversize;
        layerWidthOversize = settings.qOversize;
      }
    } catch (e) {
      // parse error
    }

    const minTakeAway = !isNaN(layerMinTakeaway) ? layerMinTakeaway : 5; // mm
    const boardWidth = layer.rawWidth;
    const panelWidth = parseFloat(toServer.panelWidth);
    const panelLength = parseFloat(toServer.panelLength);
    const oversize = !isNaN(layerOversize) && !force ? parseFloat(layerOversize) : parseFloat(toServer.oversize);
    const oversizeWidth = !isNaN(layerWidthOversize) && !force ? parseFloat(layerWidthOversize) : parseFloat(toServer.oversizeWidth);
    let boards = 0;
    let panelDimension = -1;

    //console.log('schilligerChildCalculateValues VALS: ', minTakeAway, boardWidth, panelWidth, panelLength, oversize, oversizeWidth);
    if (orient == 1) {
      // L
      panelDimension = panelWidth + oversizeWidth;
    } else if (orient == 5) {
      // LTH
      panelDimension = panelWidth;
    } else if (orient == 6) {
      // M
      panelDimension = panelWidth;
    }

    if (orient == 2) {
      // Q
      panelDimension = panelLength + oversize;
    } else if (orient == 4) {
      // QTH
      panelDimension = panelLength;
    }

    //console.log('schilligerChildCalculateValues panelDimension: ', panelDimension, ' for orient.: ', orient);

    const boardPlanedMinWidth = boardWidth - minTakeAway;
    boards = Math.ceil(panelDimension / boardPlanedMinWidth);
    const takeaway = boardWidth - panelDimension / boards;

    let width = boardWidth - takeaway;
    width = Math.round(width * 100) / 100;
    const number_of_lamellas = boards;

    // VALIDATOR TEST
    let layerData = calculateWoodIns(layer, toServer);
    console.log('layerData', layerData);

    /* L - LONG */
    if (orient == 1) {
      const lamellaLength = toServer.panelLength;
      if (width > 0) {
        change.lamellaWidth = width;
        change.numOfLamellas = number_of_lamellas;
        change.numOfLamellasWood = number_of_lamellas;
        change.numOfLamellasIns = 0;
        change.lamellaLength = lamellaLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 1;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 5) {
      /* LTH - LONG THERM */
      const lamellaLength = toServer.panelLength;
      if (width > 0) {
        //console.log('LTH - LONG THERM');
        change.lamellaWidth = LAMELLA_WIDTH;
        //change.lamellaWidth = width;
        change.numOfLamellas = calculateWoodIns(layer, toServer).trueCount + calculateWoodIns(layer, toServer).falseCount;
        change.numOfLamellasWood = layerData?.trueCount;
        change.numOfLamellasIns = layerData?.falseCount;
        change.lamellaLength = lamellaLength;
        change.oversize = 0;
        change.oversizeWidth = change.oversizeWidth;
        change.orientation = 5;
        change.widthoffset = change.widthoffset;
        change.panelWidth = toServer.panelWidth;
        change.panelLength = toServer.panelLength;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 2) {
      /* Q - CROSS */
      const lamellaLength = toServer.panelWidth;
      if (width > 0) {
        change.lamellaWidth = width;
        change.numOfLamellas = number_of_lamellas;
        change.numOfLamellasIns = 0;
        change.lamellaLength = lamellaLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 2;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 4) {
      /* Q - CROSS THERM */
      const lamellaLength = toServer.panelWidth;
      if (width > 0) {
        change.lamellaWidth = parseFloat((calculateWoodIns(layer, toServer)?.woodWidth || 0).toFixed(2));
        //change.numOfLamellas = number_of_lamellas;
        change.numOfLamellas = calculateWoodIns(layer, toServer).trueCount + calculateWoodIns(layer, toServer).falseCount;
        change.numOfLamellasWood = calculateWoodIns(layer, toServer).trueCount;
        change.numOfLamellasIns = calculateWoodIns(layer, toServer).falseCount;
        change.lamellaLength = lamellaLength;
        change.oversize = 0;
        change.oversizeWidth = change.oversizeWidth;
        change.orientation = 4;
        change.panelWidth = toServer.panelWidth;
        change.panelLength = toServer.panelLength;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 6) {
      // LengthOffset
      const lamellaLength = toServer.panelLength;
      if (width > 0) {
        change.lamellaWidth = parseFloat((calculateWoodIns(layer, toServer)?.woodWidth || 0).toFixed(2));
        change.numOfLamellas = calculateLengthOffset(layer);
        change.numOfLamellasWood = calculateWoodIns(layer, toServer).trueCount;
        change.numOfLamellasIns = calculateWoodIns(layer, toServer).falseCount;
        change.lamellaLength = lamellaLength;
        change.panelWidth = toServer.panelWidth;
        change.panelLength = toServer.panelLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 1;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    }
    if (rotation !== undefined) {
      change.rotation = rotation;
    } else {
      change.rotation = layer.rotation;
    }

    // arboreal
    if (isNaN(change.rotation)) {
      change.rotation = 0;
    }

    change.orientation = orient;
    if (index >= 0) {
      changeModelState((prevNewState) =>
        produce(prevNewState, (draft) => {
          draft.item[form.childFieldName][index] = change;
        })
      );
    } else {
      changeModelState((prevNewState) =>
        produce(prevNewState, (draft) => {
          draft.newChild = change;
        })
      );
    }
  }
}

export function smartlamPlanerSetupCalculator(form, item, changeModelState) {
  const change = JSON.parse(JSON.stringify(item));
  try {
    let width = parseFloat(item['rawWidth']);
    let plannedWidth = parseFloat(item['lamellaPlannedWidth']);
    let diff = width - plannedWidth;

    if (diff >= 0) {
      change.W1Takeaway = diff / 2;
      change.W3Takeaway = diff / 2;
    }

    changeModelState((prevNewState) =>
      produce(prevNewState, (draft) => {
        draft.item = change;
      })
    );
  } catch (e) {
    console.log(e);
  }
}

export function peetriChildCalculateValues(form, layer, index, force, changeModelState, toServer, settings) {
  // console.log('peetriChildCalculateValues: ', form, layer, toServer, settings);
  // console.log('peetriChildCalculateValues layer: ', layer);
  // console.log('peetriChildCalculateValues toServer: ', toServer);

  const change = JSON.parse(JSON.stringify(layer));
  const orient = parseInt(layer.orientation);
  const rotation = parseInt(layer.rotation);

  const LAMELLA_WIDTH = 100;
  const INSULATION_WIDTH = 262.5;

  change.layerNo = layer.layerNo;
  change.quality = layer.quality;
  change.lamellaLength = layer.lamellaLength;
  change.lamellaThickness = layer.lamellaThickness;
  change.lamellaWidth = layer.lamellaWidth;
  change.rawThickness = layer.rawThickness;
  change.rawWidth = layer.rawWidth;
  change.widthoffset = layer.widthoffset != undefined && layer.widthoffset !== '' ? layer.widthoffset : [];

  /* CLT */
  if (!toServer.isKVH) {
    let layerMinTakeaway, /* layerMaxTakeaway, */ layerOversize, layerWidthOversize;
    try {
      if (settings !== undefined) {
        layerMinTakeaway = settings.minTakeaway;
        // layerMaxTakeaway = settings.maxTakeaway;
        layerOversize = settings.lOversize;
        layerWidthOversize = settings.qOversize;
      }
    } catch (e) {
      // parse error
    }

    const minTakeAway = !isNaN(layerMinTakeaway) ? layerMinTakeaway : 0; // mm
    const boardWidth = layer.rawWidth;
    const panelWidth = parseFloat(toServer.panelWidth);
    const panelLength = parseFloat(toServer.panelLength);
    const oversize = !isNaN(layerOversize) && !force ? parseFloat(layerOversize) : parseFloat(toServer.oversize);
    const oversizeWidth = !isNaN(layerWidthOversize) && !force ? parseFloat(layerWidthOversize) : parseFloat(toServer.oversizeWidth);
    let boards = 0;
    let panelDimension = -1;

    //console.log('schilligerChildCalculateValues VALS: ', minTakeAway, boardWidth, panelWidth, panelLength, oversize, oversizeWidth);
    if (orient == 1) {
      // L
      panelDimension = panelWidth + oversizeWidth;
    } else if (orient == 5) {
      // LTH
      panelDimension = panelWidth;
    } else if (orient == 6) {
      // M
      panelDimension = panelWidth;
    }

    if (orient == 2) {
      // Q
      panelDimension = panelLength + oversize;
    } else if (orient == 4) {
      // QTH
      panelDimension = panelLength;
    }

    //console.log('schilligerChildCalculateValues panelDimension: ', panelDimension, ' for orient.: ', orient);

    const boardPlanedMinWidth = boardWidth - minTakeAway;
    boards = Math.ceil(panelDimension / boardPlanedMinWidth);
    const takeaway = boardWidth - panelDimension / boards;

    let width = boardWidth - takeaway;
    width = Math.round(width * 100) / 100;
    const number_of_lamellas = boards;

    /* L - LONG */
    if (orient == 1) {
      const lamellaLength = toServer.panelLength;
      if (width > 0) {
        change.lamellaWidth = width;
        change.numOfLamellas = number_of_lamellas;
        change.numOfLamellasWood = number_of_lamellas;
        change.numOfLamellasIns = 0;
        change.lamellaLength = lamellaLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 1;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 5) {
      /* LTH - LONG THERM */
      const lamellaLength = toServer.panelLength;
      if (width > 0) {
        change.numOfLamellasIns = change.widthoffset.length;
        change.numOfLamellas = change.numOfLamellasWood + change.numOfLamellasIns;
        change.lamellaLength = lamellaLength;
        change.oversize = 0;
        change.orientation = 5;
        change.panelWidth = toServer.panelWidth;
        change.panelLength = toServer.panelLength;
        console.log('change', change);
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    } else if (orient == 2) {
      /* Q - CROSS */
      const lamellaLength = toServer.panelWidth;
      if (width > 0) {
        change.lamellaWidth = width;
        change.numOfLamellas = number_of_lamellas;
        change.numOfLamellasIns = 0;
        change.lamellaLength = lamellaLength;
        change.oversize = oversize;
        change.oversizeWidth = oversizeWidth;
        change.orientation = 2;
      } else {
        change.lamellaWidth = '';
        change.numOfLamellas = '';
        change.lamellaLength = '';
        change.oversize = '';
        change.oversizeWidth = '';
        change.orientation = '';
      }
    }

    if (rotation !== undefined) {
      change.rotation = rotation;
    } else {
      change.rotation = layer.rotation;
    }

    // arboreal
    if (isNaN(change.rotation)) {
      change.rotation = 0;
    }

    change.orientation = orient;
    if (index >= 0) {
      changeModelState((prevNewState) =>
        produce(prevNewState, (draft) => {
          draft.item[form.childFieldName][index] = change;
        })
      );
    } else {
      changeModelState((prevNewState) =>
        produce(prevNewState, (draft) => {
          draft.newChild = change;
        })
      );
    }
  }
}

function calculateWoodIns(l, o) {
  //console.log('calculateWoodIns');
  let error = false;
  if (l !== null && o !== null) {
    let lamellas = [];
    let lastOffset = 0;
    if (l.widthoffset != undefined) {
      var sortedWidthOffset = l.widthoffset.slice().sort((a, b) => a.offset - b.offset);

      if (sortedWidthOffset.length > 0 && l.lamellaWidth > 0) {
        for (let i = 0; i < sortedWidthOffset.length; i++) {
          let woff = sortedWidthOffset[i];
          let woodWidth = woff.offset - lastOffset;
          let woodLamellas = Math.round(woodWidth / l.lamellaWidth);
          let woodDiff = woodWidth - woodLamellas * l.lamellaWidth;
          error = woodDiff != 0;
          let widthoffsetLamellas = Math.round(woff.width / l.lamellaWidth);

          console.log('widthoffsetLamellas: ', widthoffsetLamellas, ' = ', woff.width, ' / ', l.lamellaWidth);
          //console.log('schilliger calculateWoodIns: ', i + ' ' + l.lamellaWidth + ' ' + woff.offset + ' ' + woff.width + ' ' + woodLamellas + ' ' + widthoffsetLamellas);
          lastOffset = woff.offset + woff.width;
          for (let j = 0; j < woodLamellas; j++) lamellas.push(true);
          for (let j = 0; j < widthoffsetLamellas; j++) lamellas.push(false);
        }
      }
    }
    // Calculate first lamella width
    let firstNumberOfLamellas = Math.round(sortedWidthOffset[0].offset / l.rawWidth);
    let actualLamellaPlannedWidth = sortedWidthOffset[0].offset / firstNumberOfLamellas;

    if (l.lamellaWidth > 0) {
      let endDimension = l.orientation === 4 || l.orientation === 2 ? o.panelLength : o.panelWidth;
      let woodLamellas = Math.round((endDimension - lastOffset) / l.lamellaWidth);
      for (let j = 0; j < woodLamellas; j++) lamellas.push(true);
    }
    let trueCount = 0;
    let falseCount = 0;

    lamellas.forEach((element) => {
      if (element === true) {
        trueCount++;
      } else {
        falseCount++;
      }
    });
    // console.log('# calculateWoodIns lamellas: ', lamellas);
    return { trueCount: trueCount, falseCount: falseCount, error: error, woodWidth: actualLamellaPlannedWidth };
  }
}

function calculateLengthOffset(l) {
  if (l !== null) {
    let lamellas = [];
    let lastOffset = 0;
    if (l.widthoffset != undefined) {
      var sortedWidthOffset = l.widthoffset.slice().sort((a, b) => a.offset - b.offset);

      if (sortedWidthOffset.length > 0 && l.lengthoffsetWidth > 0) {
        for (let i = 0; i < sortedWidthOffset.length; i++) {
          let woff = sortedWidthOffset[i];
          let woodWidth = woff.offset - lastOffset;
          let woodLamellas = Math.round(woodWidth / l.lengthoffsetWidth);
          let widthoffsetLamellas = Math.round(woff.width / l.lengthoffsetWidth);

          //console.log('schilliger calculateWoodIns: ', i + ' ' + ins.offset + ' ' + ins.width + ' ' + woodLamellas + ' ' + widthoffsetLamellas);
          lastOffset = woff.offset + woff.width;
          for (let j = 0; j < woodLamellas; j++) lamellas.push(true);
          for (let j = 0; j < widthoffsetLamellas; j++) lamellas.push(false);
        }
      }
    }
    //console.log('DEBUG 3');

    // finish or if a normal layer

    if (l.lamellaWidth > 0) {
      let endDimension = l.orientation === 4 || l.orientation === 2 ? l.panelLength : l.panelWidth;
      let woodLamellas = Math.round((endDimension - lastOffset) / l.lamellaWidth);
      for (let j = 0; j < woodLamellas; j++) lamellas.push(true);
    }

    return lamellas.length;
  }
}

export function widthOffsetMaterial(l, props) {
  if (!l) return;

  let { formState, settings } = props;
  let { orientation, widthoffset, lamellaLength, lengthoffsetWidth, rawWidth, lamellaWidth, panelWidth } = l;

  let panelDimension = parseInt(orientation) === 6 ? panelWidth : formState.item.panelLength;
  let actualLamellaPlannedWidth = orientation === 6 ? lengthoffsetWidth : lamellaLength;

  if (widthoffset && widthoffset.length > 0) {
    // Sort widthoffset by offset
    let sortedWidthOffset = widthoffset.slice().sort((a, b) => a.offset - b.offset);

    let insulationWidth = sortedWidthOffset.reduce((total, current) => total + current.width, 0);
    let woodWidth = calculateWoodWidth(sortedWidthOffset, panelDimension);

    // Calculate first lamella width
    let firstNumberOfLamellas = Math.round(sortedWidthOffset[0].offset / rawWidth);
    actualLamellaPlannedWidth = sortedWidthOffset[0].offset / firstNumberOfLamellas;

    let lengthWithoutInsulation = panelDimension - insulationWidth;
    let qLamellaNum = calculateLamellaNum(lengthWithoutInsulation, actualLamellaPlannedWidth, orientation, lamellaWidth, settings);
    let qInsLamellaNum = Math.ceil(insulationWidth / settings.insulationWidthF);

    return {
      qLamellaNum,
      qInsLamellaNum,
      woodWidth: actualLamellaPlannedWidth,
      insWidth: settings.insulationWidthF,
      error: false
    };
  }
}

function calculateWoodWidth(sortedWidthOffset, panelDimension) {
  let woodWidth = 0;

  for (let i = 0; i < sortedWidthOffset.length; i++) {
    if (i === 0) {
      woodWidth += sortedWidthOffset[i].offset;
    } else if (i < sortedWidthOffset.length - 1) {
      woodWidth += sortedWidthOffset[i + 1].offset - (sortedWidthOffset[i].offset + sortedWidthOffset[i].width);
    } else {
      woodWidth += panelDimension - (sortedWidthOffset[i].offset + sortedWidthOffset[0].width);
    }
  }

  return woodWidth;
}

function calculateLamellaNum(lengthWithoutInsulation, actualLamellaPlannedWidth, orientation, lamellaWidth, settings) {
  if (orientation === 4) {
    return Math.ceil(lengthWithoutInsulation / actualLamellaPlannedWidth);
  } else {
    return Math.ceil((lengthWithoutInsulation + settings.qOversize) / (lamellaWidth - settings.minTakeaway));
  }
}

export function lengthOffsetMaterial(l) {
  if (l !== null) {
    let lamellas = [];
    let lastOffset = 0;
    if (l.widthoffset !== undefined) {
      var sortedWidthOffset = l.widthoffset.slice().sort((a, b) => a.offset - b.offset);

      if (sortedWidthOffset.length > 0 && l.lengthoffsetWidth > 0) {
        for (let i = 0; i < sortedWidthOffset.length; i++) {
          let woff = sortedWidthOffset[i];
          let woodWidth = woff.offset - lastOffset;
          let woodLamellas = Math.round(woodWidth / l.lengthoffsetWidth);
          let widthoffsetLamellas = Math.round(woff.width / l.lengthoffsetWidth);

          lastOffset = woff.offset + woff.width;
          for (let j = 0; j < woodLamellas; j++) lamellas.push(true); // Wood lamellas
          for (let j = 0; j < widthoffsetLamellas; j++) lamellas.push(false); // Insulation (or width offset) lamellas
        }
      }
    }

    if (l.lamellaWidth > 0) {
      let endDimension = l.orientation === 4 || l.orientation === 2 ? l.panelLength : l.panelWidth;
      let woodLamellas = Math.round((endDimension - lastOffset) / l.lamellaWidth);
      for (let j = 0; j < woodLamellas; j++) lamellas.push(true);
    }

    // Count the number of false values
    let falseCount = lamellas.filter((lamella) => lamella === false).length;

    return { shortCount: falseCount * l.lengthoffset.length, shortWidth: l.lengthoffsetWidth };
  }
}
